@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:regular,bold,italic&subset=latin,latin-ext);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root { font: 20px/1.2 "Roboto", sans-serif; }

body,
body * {
	margin:          unset;
	box-sizing:      unset;
	padding:         unset;
	font-size:       unset;
	color:           unset;
	text-decoration: unset;
}

figure * { display: block; }
article {
	padding: 0.5in 0;
	
	display: grid;
	grid-template-columns: 1fr 0.5in [start] 1.25in 5in 1.25in [end] 0.5in 1fr;
	
	background: linear-gradient(hsl(55, 100%, 96%), white 6.55rem);
}

article * { grid-column: start / end; }

@media (max-width: 8.5in) {
	article { grid-template-columns: 1fr 5% [start] 15% 60% 15% [end] 5% 1fr; }
}
.share {
	display: flex;
	justify-content: center; 
}

.share img {
	padding: 0.75rem;
	width:   1.50rem;
	height:  1.50rem;
}


h2, p { margin-bottom: 2.4rem; }

h1           { font: 700 2.0rem/1.2 "Roboto"; color: hsl(000, 000%, 33%); }
h2           { font: 400 1.5rem/1.2 "Roboto"; color: hsl(000, 000%, 83%); }
time         { font: 700 1.0rem/1.2 "Roboto"; color: hsl(250, 100%, 83%); }
h3           { font: 700 1.0rem/1.2 "Roboto"; color: hsl(250, 100%, 67%); }
p            { font: 400 1.0rem/1.5 "Roboto"; color: hsl(000, 000%, 33%); }
figcaption p { font: 400 1.0rem/1.5 "Roboto"; color: hsl(000, 000%, 83%); }

a { box-shadow: inset 0 -0.25em hsl(55, 100%, 75%); }

@media (max-width: 8.5in) { :root { font-size: 18px; } }
@media (max-width: 5.0in) { :root { font-size: 16px; } }
.size-1 { grid-column: 4 / 5; }
.size-2 { grid-column: 3 / 6; }
.size-3 { grid-column: 2 / 7; }
.size-4 { grid-column: 1 / 8; }

.size-1 img, .size-2 img, .size-3 img, .size-4 img { width: 100%; }

figcaption {
    margin-top: 0.5rem;
    
    display: flex;
	justify-content: center;    
}
