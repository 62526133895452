.size-1 { grid-column: 4 / 5; }
.size-2 { grid-column: 3 / 6; }
.size-3 { grid-column: 2 / 7; }
.size-4 { grid-column: 1 / 8; }

.size-1 img, .size-2 img, .size-3 img, .size-4 img { width: 100%; }

figcaption {
    margin-top: 0.5rem;
    
    display: flex;
	justify-content: center;    
}