

h2, p { margin-bottom: 2.4rem; }

h1           { font: 700 2.0rem/1.2 "Roboto"; color: hsl(000, 000%, 33%); }
h2           { font: 400 1.5rem/1.2 "Roboto"; color: hsl(000, 000%, 83%); }
time         { font: 700 1.0rem/1.2 "Roboto"; color: hsl(250, 100%, 83%); }
h3           { font: 700 1.0rem/1.2 "Roboto"; color: hsl(250, 100%, 67%); }
p            { font: 400 1.0rem/1.5 "Roboto"; color: hsl(000, 000%, 33%); }
figcaption p { font: 400 1.0rem/1.5 "Roboto"; color: hsl(000, 000%, 83%); }

a { box-shadow: inset 0 -0.25em hsl(55, 100%, 75%); }

@media (max-width: 8.5in) { :root { font-size: 18px; } }
@media (max-width: 5.0in) { :root { font-size: 16px; } }