@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:regular,bold,italic&subset=latin,latin-ext');


article {
	padding: 0.5in 0;
	
	display: grid;
	grid-template-columns: 1fr 0.5in [start] 1.25in 5in 1.25in [end] 0.5in 1fr;
	
	background: linear-gradient(hsl(55, 100%, 96%), white 6.55rem);
}

article * { grid-column: start / end; }

@media (max-width: 8.5in) {
	article { grid-template-columns: 1fr 5% [start] 15% 60% 15% [end] 5% 1fr; }
}